import { defineFormKitConfig  } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { fr } from '@formkit/i18n'
// import { generateClasses } from '@formkit/themes'
// import myTailwindTheme from '~/assets/css/formkit-tailwind.js'
import { rootClasses } from './formkit.theme'
// import '@formkit/themes/genesis'
// import '@formkit/pro/genesis'

export default defineFormKitConfig(() => {
  // Creating the Pro plugin
  const config = useRuntimeConfig()
  const proPlugin = createProPlugin(config.public.FORMKIT_PRO_KEY, inputs)

  // Returning the configuration
  return {
    plugins: [proPlugin],
    locales: { fr },
    locale: 'fr',
    config: {
      rootClasses,
      // classes: generateClasses(myTailwindTheme),
    },
  }
})