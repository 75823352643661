import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { public: { NODE_ENV, PROJECT_VERSION } } = useRuntimeConfig()
  const { vueApp } = nuxtApp
  const router = useRouter()

  // Forcing a refresh of the page to make sure the new version is loaded when deployment-related errors occur
  // Error example : https://trident-app.sentry.io/issues/4124296729
  router.onError((error, to) => {
    if (isDeploymentError(error)) {
      // Preventing infinite reloads
      const lastForceRefresh = window.localStorage.getItem('lastForceRefresh')
      if (lastForceRefresh && Date.now() - parseInt(lastForceRefresh) < 1000) {
        return
      } else {
        window.localStorage.setItem('lastForceRefresh', Date.now().toString())
      }

      // Adding ?forceRefresh=1 to the URL
      let newUrl = new URL(window.location.origin + to.fullPath)
      newUrl.searchParams.append('forceRefresh', '1')

      // Reloading the page
      window.location = newUrl.href as unknown as Location
    }
  })

  Sentry.init({
    app: [vueApp],
    dsn: "https://a47f88d3d1d4453f842f69954d2d70b4@o1396132.ingest.sentry.io/6719360",
    environment: NODE_ENV === 'production' ? 'production' : 'development',
    integrations: [
      Sentry.browserTracingIntegration({
        router: router,
      }),
    ],
    tracesSampleRate: 0.05,
    release: PROJECT_VERSION,
    beforeSend (event, hint) {
      if (isDeploymentError(hint.originalException as Error)) {
        console.error(`[Deployment error, not sending to Sentry]: (${hint.originalException})`, { event, hint })
        return null
      }
      if (event.exception && NODE_ENV !== 'development') {
        console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint })
      }
      return NODE_ENV === 'development' ? null : event 
    }
  })

  vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }))
  Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'], attachErrorHandler: true })

  return {
    provide: {
      Sentry
    }
  }
})

function isDeploymentError(error: Error) {  
  const possibleDeploymentErrors = [
    'Failed to fetch dynamically imported module',
    'Importing a module script failed',
    'Unable to preload CSS for'
  ]
  return possibleDeploymentErrors.some((possibleError) => error?.message?.includes(possibleError))
}