<script setup lang="ts">
  import { provideUseId } from '@headlessui/vue'

  // Fixing the Vue Warn of "Hydration attribute mismatch" about headlessui
  provideUseId(() => useId()?.replace(/[-:]/, '_') ?? '')

  const runtimeConfig = useRuntimeConfig()
  const router = useRouter()
  const { openNotifications, addInfoNotification } = useNotifications()

  // Adding a notification when a force refresh happened
  onMounted(() => {
    const route = router.currentRoute.value
    if (route.query.forceRefresh === '1') {
      addInfoNotification({
        title: 'La page a été mise à jour',
        message: 'Vous utilisez maintenant la dernière version du site web.',
        timeout: 5000
      })
    }
  })

  // Setting the user on PostHog and Sentry
  const { $posthog, $Sentry } = useNuxtApp()
  const user = useSupabaseUser()
  watch(user, () => {
    if (user.value) {
      console.log('Setting the user in Posthog and Sentry')
      $posthog?.identify(user.value?.id, { email: user.value?.email })
      $Sentry?.setUser(user.value)
    } else {
      console.log('Resetting the user in Posthog and Sentry')
      $posthog?.reset()
      $Sentry?.setUser(null)
    }
  }, { immediate: true })
</script>

<template>
  <NuxtLoadingIndicator :throttle="100" :color="runtimeConfig.public.tailwindExtendColors.skyBlue.DEFAULT" />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <!-- Global notification live region -->
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50">
    <div id="containerToastNotifications" class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <ToastNotification
        v-for="notification in openNotifications"
        :key="notification.id"
        :id="notification.id"
        />     
    </div>
  </div>
</template>
