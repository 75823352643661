import type { Notification, NotificationParam, TypedNotificationParam } from "~~/types/notification.types"

let lastGivenID = 0
const defaultTimeout = 6 * 1000
const notifications: Notification[] = reactive([])
const openNotifications = computed(() => {
  return notifications.filter(notification => notification.isOpen).sort((a, b) => b.id - a.id)
})

export default function () {

  const addNotification = ({ type, title, message, timeout }: NotificationParam): Notification['id'] => {
    // Creation of the notification
    const newNotificationId = lastGivenID++
    notifications.push({
      id: newNotificationId,
      isOpen: true,
      needToBeClosed: false,
      type,
      title,
      message
    })

    // Close the notification after the time wanted (or not closing if null)
    if (timeout !== null) {
      setTimeout(() => {
        const notificationToClose = getNotificationByID(newNotificationId)
        notificationToClose.needToBeClosed = true // signal for making the component handle the closing with an animation
      }, timeout ?? defaultTimeout)
    }

    return newNotificationId
  }

  /**
   * Adding a notification with a pre-determined type
   */
  const addSuccessNotification = (details: TypedNotificationParam) => {
    return addNotification({
      type: 'success',
      message: details.message,
      title: details.title ?? 'Succès de l\'opération',
      timeout: details.timeout ?? defaultTimeout
    })
  }
  const addErrorNotification = (details: TypedNotificationParam) => {
    return addNotification({
      type: 'error',
      message: details.message,
      title: details.title ?? 'Une erreur est survenue',
      timeout: details.timeout ?? null
    })
  }
  const addWarningNotification = (details: TypedNotificationParam) => {
    return addNotification({
      type: 'warning',
      message: details.message,
      title: details.title ?? 'Faîtes attention',
      timeout: details.timeout ?? null
    })
  }
  const addInfoNotification = (details: TypedNotificationParam) => {
    return addNotification({
      type: 'info',
      message: details.message,
      title: details.title ?? 'Information complémentaire',
      timeout: details.timeout ?? null
    })
  }

  /**
   * Returns the notification with the given ID
   */
  const getNotificationByID = (id: Notification['id']): Notification => {
    const notification = notifications.find(notification => notification.id === id)
    if (notification) {
      return notification
    } else {
      throw new Error('Wrong notification ID')
    }
  }

  /**
   * Close a notification by its ID
   */
  const closeNotification = (id: Notification['id']): void => {
    const notificationToClose = getNotificationByID(id)
    notificationToClose.isOpen = false
  }

  return {
    notifications,
    openNotifications,
    addNotification,
    addSuccessNotification,
    addInfoNotification,
    addErrorNotification,
    addWarningNotification,
    closeNotification,
    getNotificationByID
  }
}