<script setup lang="ts">
  import type { Notification } from '~~/types/notification.types';
  import { XCircleIcon, CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
  import { XMarkIcon } from '@heroicons/vue/24/solid'

  const props = defineProps<{
    id: Notification['id']
  }>()

  const { getNotificationByID, closeNotification } = useNotifications()
  const notification = getNotificationByID(props.id)

  // Variables for this component
  const isShowing = ref<boolean>(false)
  const enterDuration = ref<number>(300)
  const leaveDuration = ref<number>(100)

  // Making the enter-transition possible
  onMounted(() => {
    setTimeout(() => {
      isShowing.value = notification.isOpen
    }, 100)
  })

  // Making the leave-transition possible
  const gentleClose = (): void => {
    isShowing.value = false
    setTimeout(() => {
      closeNotification(notification.id)
    }, leaveDuration.value)
  }

  // Detecting when to remove the notification
  watch(() => notification.needToBeClosed, () => {
    if (notification.needToBeClosed) {
      gentleClose()
    }
  })
</script>

<template>
  <transition :enter-active-class="`transform ease-out duration-${enterDuration} transition`" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" :leave-active-class="`transition ease-in duration-${leaveDuration}`" leave-from-class="opacity-100" leave-to-class="opacity-0">
    <div v-if="isShowing" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <CheckCircleIcon v-if="notification.type === 'success'" class="h-6 w-6 text-green-cucumber-400" aria-hidden="true" />
            <XCircleIcon v-else-if="notification.type === 'error'" class="h-6 w-6 text-red-400" aria-hidden="true" />
            <ExclamationTriangleIcon v-else-if="notification.type === 'warning'" class="h-6 w-6 text-orange-400" aria-hidden="true" />
            <InformationCircleIcon v-else-if="notification.type === 'info'" class="h-6 w-6 text-blue-400" aria-hidden="true" />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">
              {{ notification.title }}
            </p>
            <p class="mt-1 text-sm text-gray-500">
              {{ notification.message }}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button @click="gentleClose()" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue-500">
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>